type InjectDynamicImportsProps = {
  sduiImports: {
    [key: string]: () => any
  },
  config: any
}

export type Component = {
  export: string;
  name: string;
  props: {
    [key: string]: any
  }
}

export type SduiData = {
  slots: {
    [key: string]: any
  }
}

export interface Config {
  slots: Slot[]
}

export type Slot = {
  slot: string;
  section:string;
  isDynamic: boolean;
  disabled:boolean;
  isSDUI: boolean;
  isAccordion: boolean;
  // accordions are nested slots
  components: Component[] | Slot[][]
}

export type SlotDictionary = {
  [key: string]: Slot
}

type DynamicLoad = {
  [i: string]: {
    [i: number]: (() => Component) | Component[]
  }
}

/**
 *
 * @param {Object} props
 * @param {Object} props.dynamicImports - the * exports from the DynamicImports.js file
 * for example: `import * as dynamicImportsLoad from '../components/DynamicImports';`
 * @param {Object} config - the fusion config
 * @returns and updated config object with the DynamicImportsLoad promise attached to the component.
 * This must be exist to turn the config.slots from an array into an object with keyname of `slot<n>`
 * while also exporting the dynamic import promise to the load attribute on the config component.  This
 * can then later be used to pass into the hydrator and other things that need to listen to when it completes.
 */
export const injectImports = ({ sduiImports, config }: InjectDynamicImportsProps) => {

  const dynamicLoad = {} as DynamicLoad;
  const keys = Object.keys(sduiImports);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (/Slot[A-Z]\d+Index\d+/.test(key)) {
      const [, s, i, n] = /Slot([A-Z])(\d+)Index(\d+)/.exec(key);
      const SectionIndex = s + i;
      const nAsInt = parseInt(n, 10);
      if (!dynamicLoad[SectionIndex]) {
        dynamicLoad[SectionIndex] = {};
      }
      dynamicLoad[SectionIndex][nAsInt] = sduiImports[key];
    }
  }
  let slotIndex = 0;
  let section = '';

  const slotProps = config.slots.reduce((acc:SlotDictionary, cur: Slot, index:number) => {

    if (cur.disabled) {
      return acc;
    }

    if (section !== cur.section) {
      slotIndex = 0;
      section = cur.section;
    }
    // eslint-disable-next-line no-plusplus
    slotIndex++;

    return {
      ...acc,
      [`slot${cur.section}${slotIndex}`]: {
        ...cur,
        slot: slotIndex,
        components: cur.isSDUI
          ? cur.components.map((com, n: number) => {
            if (Array.isArray(com)) {
              return com.map((cp:Slot, nd:number) => ({
                ...cp, load: dynamicLoad[cur.section + slotIndex][n]
              }));
            }
            return { ...com, load: dynamicLoad[cur.section + slotIndex][n] };
          })
          : cur.components,
      },
    };
  }, {});

  return {
    ...config,
    slots: slotProps,
  };
};

type BreakPoint = {
  classNames?: string[];
  cols?: string;
  direction?: string;
  sticky?: boolean;
}

type GridItem = {
  default?: BreakPoint;
  sm?: BreakPoint;
  md?: BreakPoint;
  lg?: BreakPoint;
  xl?: BreakPoint;
  __typename?: string;
}

export const parseGrid = (gridItem: GridItem) => {
  const { ...breakpoints } = gridItem;
  if (!breakpoints) return {};

  const keys = Object.keys(breakpoints) as (keyof GridItem)[];
  const classNames = keys
    .map((breakpoint) => {
      if (!breakpoints[breakpoint]) return null;
      if (breakpoint === '__typename') return null;
      const { cols, sticky, classNames: cnames = [] } = breakpoints[breakpoint];
      return (cnames || []).map((name) => {
        const suiClass = name.indexOf("sui-") === -1 ? `sui-${name}` : name;
        return breakpoint === 'default' ? suiClass : `${breakpoint}:${suiClass}`;
      }).join(' ');
    })
    .filter((val) => val);

  return classNames.join(' ');
};

export const hasSDUI = (config: Config) => {
  return config.slots.some((slot) => {
    if (slot.isSDUI) return true;

    if (slot.isAccordion) {

      const components = slot.components as Slot[][];
      return components.some((headOrBody) => {
        return headOrBody.some((component) => {
          return component.isSDUI;
        });
      });
    }
    return false;
  });
};

export const getSduiComponents = (config: Config) => {
  return config.slots.filter((slot) => {
    if (slot.isSDUI) return true;

    // @todo accordion
    // if (slot.isAccordion) {

    //   const components = slot.components as Slot[][];
    //   return components.some((headOrBody) => {
    //     return headOrBody.some((component) => {
    //       return component.isSDUI;
    //     });
    //   });
    // }
    return false;
  });
};

// when sdui data comes back from the api it has to be injected into the config prop data
// its possible sdui can change the prop values.
export const injectSduiDataToSlot = (slot: Slot, data: SduiData, slotName: string) => {
  try {
    if (data?.slots?.[slotName]) {
      if (data.slots[slotName].component) {
        const sduiComponent = data.slots[slotName].component.data;
        const index = data.slots[slotName].component.index;
        const component = slot.components[index] as Component;
        const currentProps = component.props;

        component.props = {
          ...currentProps,
          ...sduiComponent.props,
        };
      } else if (data.slots[slotName].accordion) {
        const sduiAccordionData = data.slots[slotName].accordion;
        slot.components.forEach((headOrBody, headOrBodyIndex) => {
          (headOrBody as Slot[]).forEach((innerSlot, accordionIndex) => {
            (innerSlot.components as Component[]).forEach((component, componentIndex) => {
              const sduiData = sduiAccordionData[headOrBodyIndex][accordionIndex];
              if (componentIndex === sduiData.index) {
                // hydrator may not be extended
                if (sduiData.data.props.hydrator) {
                  delete sduiData.data.props.hydrator;
                }
                // eslint-disable-next-line
                component.props = {
                  ...component.props,
                  ...(sduiData.data.props || {})
                };
              }
            });
          });
        });
      }
    }
  } catch (ex) {
    console.log(ex);
  }
};

type IMergeProps = {
  query: string;
  mounted: boolean;
  allQueryKeys: string[]
};

export const mergeProductClientOnlyProduct = {
  productClientOnlyProduct: {
    queries: ['product', 'clientOnlyProduct'],
    fn: ({ query, mounted, allQueryKeys }: IMergeProps) => {

      if (!mounted) return query;

      if (allQueryKeys.includes('product') && allQueryKeys.includes('clientOnlyProduct')) {
        if (query === 'product' || query === 'clientOnlyProduct') {
          return 'productClientOnlyProduct';
        }
      }

      return query;
    }
  }
};
