import React, { useEffect, useRef } from 'react';
import { string, bool } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Link } from '@one-thd/sui-atomic-components';
import { SalientPoints } from '../component/SalientPoints';
import { SalientListItem } from '../component/SalientListItem';
import { PIPSampleAnchorSku } from './PIPSampleAnchorSku';

export const PIPSalientPoints = ({ itemId, showSampleAnchorSku }) => {
  const setExpandedRef = useRef();
  const productDetailsRef = useRef();
  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('accordion.mount', ({ output }) => {
      const { wrapperIds, setExpanded } = output;
      setExpandedRef.current = setExpanded;
      productDetailsRef.current = document.querySelector('#' + wrapperIds[0]);
    }
    );
  }, []);

  const handleClick = (evt) => {
    if (setExpandedRef.current && productDetailsRef.current) {
      setExpandedRef.current(0)(evt, true);
      const element = productDetailsRef.current;
      const STICKY_HEADER_HEIGHT = 125;
      const top = element.getBoundingClientRect().top + window.pageYOffset - STICKY_HEADER_HEIGHT;
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  return (
    <>
      {showSampleAnchorSku && (
        <PIPSampleAnchorSku
          itemId={itemId}
          showSampleAnchorSku={showSampleAnchorSku}
        />
      )}
      <SalientPoints itemId={itemId} max={3} data-component="PIPSalientPoints">
        <SalientListItem>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            underline="always"
            onClick={handleClick}
            data-testid="view-more"
          >
            View More Details
          </Link>
        </SalientListItem>
      </SalientPoints>
    </>
  );
};

PIPSalientPoints.displayName = 'PIPSalientPoints';
PIPSalientPoints.propTypes = {
  itemId: string.isRequired,
  showSampleAnchorSku: bool,
};
PIPSalientPoints.defaultProps = {
  showSampleAnchorSku: false,
};
PIPSalientPoints.dataModel = extend({}, PIPSampleAnchorSku, SalientPoints);
