import React from 'react';
import { func, string } from 'prop-types';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { FBTProvider } from './contexts/FBTContext';
import { fbtRecsDataModel } from './dataModel';
import { RecsFBT } from './RecsFBT/RecsFBT.component';
import { FBTPlaceholder } from './FBTPlaceholder.component';
import './thd-recs-fbt.module.scss';

const FrequentlyBoughtTogether = (props) => {
  const { itemId, originalConfigId, onLoadDynamicRecs, apiName } = props;

  return (
    <FBTProvider
      itemId={itemId}
      originalConfigId={originalConfigId}
      apiName={apiName}
      onLoadDynamicRecs={onLoadDynamicRecs}
    >
      <RecsFBT />
    </FBTProvider>
  );
};

FrequentlyBoughtTogether.displayName = 'ThdRecsFbt';

const propTypes = {
  itemId: string.isRequired,
  originalConfigId: string,
  apiName: string,
  onLoadDynamicRecs: func
};
const defaultProps = {
  onLoadDynamicRecs: null,
  originalConfigId: null,
  apiName: 'fbt'
};
FrequentlyBoughtTogether.propTypes = propTypes;
FrequentlyBoughtTogether.defaultProps = defaultProps;
FrequentlyBoughtTogether.dataModel = fbtRecsDataModel;
const HydratedFBT = withHydrator({
  delay: 1500,
  id: 'product-section-fbt',
  placeholder: (<FBTPlaceholder />),
  preserveCtxVal: 'clientStore',
  scrollBuffer: 0
}, FrequentlyBoughtTogether);

const ThdRecsFbt = withErrorBoundary(HydratedFBT);

ThdRecsFbt.propTypes = propTypes;
ThdRecsFbt.defaultProps = defaultProps;
// dataModels should be defined on the component and extended up, not at a global component level
ThdRecsFbt.dataModel = fbtRecsDataModel;
ThdRecsFbt.displayName = FrequentlyBoughtTogether.displayName;

export { ThdRecsFbt };
