import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import { Sharebelt } from '../component/Sharebelt';
import ShareBeltShareButton from '../component/modules/ShareBeltShare/ShareBeltShareButton';
import ShareBeltPrint from '../component/modules/ShareBeltPrint/ShareBeltPrint';

export const PIPSharebelt = ({ horizontal, itemId, email }) => {

  return (
    <Sharebelt horizontal={horizontal} itemId={itemId}>
      <ShareBeltShareButton email={email} />
      <ShareBeltPrint />
    </Sharebelt>
  );
};

PIPSharebelt.displayName = 'PIPSharebelt';

PIPSharebelt.propTypes = {
  itemId: PropTypes.string.isRequired,
  horizontal: PropTypes.bool,
  email: PropTypes.bool,
};

PIPSharebelt.defaultProps = {
  horizontal: true,
  email: true,
};

PIPSharebelt.dataModel = extend({}, Sharebelt);
