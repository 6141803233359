import React, { useContext, useState, useEffect } from 'react';
import { bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  PIPCalculator,
  WallAreaCalculator,
} from '@thd-olt-component-react/calculator';
import { extend } from '@thd-nucleus/data-sources';
import Buybox from '../component/Buybox';

export const BuyboxWithPIPCalculator = (props) => {
  const { hideWallAreaCalculator } = props;
  const [mounted, setMounted] = useState(false);
  const ctx = useContext(ExperienceContext);
  const { isConsumerApp } = ctx;
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <>
      {mounted && (
        <Buybox
          renderCalculator={(buyboxItemId, onCalculate) => {
            return (
              <>
                {!hideWallAreaCalculator && (
                  <WallAreaCalculator itemId={buyboxItemId} />
                )}
                <PIPCalculator
                  itemId={buyboxItemId}
                  onCalculate={onCalculate}
                />
              </>
            );
          }}
          sticky={!isConsumerApp}
          // eslint-disable-next-line
          {...props}
        />
      )}
    </>
  );
};

BuyboxWithPIPCalculator.displayName = 'BuyboxWithPIPCalculator';
BuyboxWithPIPCalculator.propTypes = {
  hideWallAreaCalculator: bool,
};
BuyboxWithPIPCalculator.defaultProps = {
  hideWallAreaCalculator: false,
};

BuyboxWithPIPCalculator.dataModel = extend(
  {},
  Buybox,
  PIPCalculator,
  WallAreaCalculator
);
