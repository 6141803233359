import React from 'react';
import { node, bool } from 'prop-types';

export const SlotWrapper = ({ children, sticky = false }) => {
  if (!sticky) return children;
  return (
    <div className="sui-sticky sui-top-0 sui-z-10">{children}</div>
  );
};

SlotWrapper.propTypes = {
  children: node.isRequired,
  sticky: bool.isRequired,
};