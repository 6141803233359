/* eslint-disable react/destructuring-assignment, react/jsx-props-no-spreading, camelcase */
/**
 *  Do not edit this file! It is autogenerated from the harmony composer
 */

import React, { useEffect, useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import {
  useHydratedProps, useDynamicSlots, withLayout, withLayoutAccordion, injectSduiDataToSlot
} from '@thd-olt-component-react/fusion-utils';

/**
 * Business rules for each component are specified in businessRules map
 *
 * */

const lazySlots = {};

// to be generated by ejs based on config
export const SDUIImporter = (props) => {

  const [mounted, setMounted] = useState(false);

  const { response = {}, loaded } = useDynamicSlots();

  const { data } = response;
  const slot = props.config.slots[props.slot];
  injectSduiDataToSlot(slot, data, props.slot);

  const indx = data?.slots?.[props.name]?.component?.index || 0;

  const components = slot.isAccordion
    ? slot.components.flat().map((sc) => sc.components).flat()
    : slot.components.filter((comp, index) => index === indx);

  const hydratedProps = useHydratedProps(components);

  components.forEach((com, index) => {
    // eslint-disable-next-line no-param-reassign
    com.props = hydratedProps[index];
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || !loaded) return <div />;

  return null;
};

SDUIImporter.propTypes = {
  config: PropTypes.shape({
    slots: PropTypes.shape({})
  }).isRequired,
  name: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
};