import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Typography, Pill } from '@one-thd/sui-atomic-components';
import { track } from './related-search-analytics';

const PopularCategory = (props) => {
  const {
    relatedLinks,
    title
  } = props;

  const renderRelatedSearch = (relatedSearchResults) => {
    let url = '';

    return (
      <div
        className="related-search__links sui-w-full sui-flex sui-flex-row sui-flex-wrap sui-leading-tight sui-gap-4"
      >
        {relatedSearchResults.map((relatedSearchResult, index) => {
          url = relatedSearchResult.url || (`/s/${encodeURIComponent(relatedSearchResult.text)}`);
          const copyText = relatedSearchResult.text;

          return (
            <div
              className="related-search__elements sui-bg-primary sui-rounded-full"
              key={`popcat-${copyText}${index}`}
            >
              <Pill
                label={copyText}
                component="a"
                href={url}
                onClick={() => track(copyText)}
                clickable
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="sui-w-full sui-flex sui-flex-col sui-gap-4">
      <Typography variant="h2" weight="display">{title}</Typography>
      {renderRelatedSearch(relatedLinks)}
    </div>
  );
};

export { PopularCategory };

PopularCategory.propTypes = {
  relatedLinks: arrayOf(shape({})).isRequired,
  title: string

};

PopularCategory.defaultProps = {
  title: 'Popular Categories'
};
