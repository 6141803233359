import React from 'react';
import { extend } from '@thd-nucleus/data-sources';
import Price, { PriceClearanceDataModel } from './Price';

// Temporary component to control which experiences get the clearance dataModel
const PIPPriceClearanceWrapper = (props) => {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Price {...props} />
    </>
  );
};

PIPPriceClearanceWrapper.displayName = 'PIPPriceClearanceWrapper';

PIPPriceClearanceWrapper.propTypes = {};

PIPPriceClearanceWrapper.defaultProps = {};

PIPPriceClearanceWrapper.dataModel = extend(Price, PriceClearanceDataModel);

export default PIPPriceClearanceWrapper;
