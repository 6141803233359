import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import { Row } from '@thd-olt-component-react/core-ui';
import { useConfigService, ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { PackagesMiniVisual } from '@thd-olt-component-react/packages-mini';
import { FrequentlyBoughtTogether } from '@thd-olt-component-react/frequently-bought-together';
import { ThdRecsFbt } from '@thd-olt-component-react/thd-recs-fbt';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

import {
  string as stringType, object, bool as boolType
} from 'prop-types';
import {
  params,
  string,
  useDataModel,
  shape,
  bool,
  arrayOf,
  extend,
  QueryProvider,
} from '@thd-nucleus/data-sources';
import ZoneCard from '../components/utils/zone-card';
import {
  shouldDisplayRecsFBT,
  shouldDisplayFbtbundle,
  shouldDisplayFBTPMV,
  shouldDisplayPipInStock,
  isDisplayableBasedOnFulfillment,
  isPackageRecommendationFlag,
  isProductAvailable
} from '../components/utils/product-utils';
import { pipRecsModel } from '../components/dataModel';

const RenderPackagesMiniVisual = (props) => {
  const {
    itemId,
  } = props;

  return (
    <PackagesMiniVisual
      itemId={itemId}
      layoutGroup={{
        tag: Row,
      }}
      dynamic={{
        pageType: 'pip'
      }}
      hydrator={{
        className: 'grid',
        tag: ZoneCard
      }}
    />
  );
};

const RenderFrequentlyBoughtTogether = (props) => {
  const {
    itemId,
  } = props;

  return (
    <FrequentlyBoughtTogether
      itemId={itemId}
      layoutGroup={{
        tag: Row,
      }}
      dynamic={{
        pageType: 'pip'
      }}
      hydrator={{
        className: 'grid',
        tag: ZoneCard
      }}
    />
  );
};

const RenderThdRecsFBT = (props) => {
  const {
    itemId,
  } = props;

  return (
    <Row>
      <ThdRecsFbt
        apiName="fbt_test"
        itemId={itemId}
        errorBoundary
        hydrator={{
          className: 'grid',
          tag: ZoneCard,
          id: 'thd-recs-fbt',
        }}
      />
    </Row>
  );
};

export const PIPFbtAndPmvLayout = (props) => {
  const storeId = useStoreId();
  const ctx = useContext(ExperienceContext);
  const { referer } = ctx;
  const {
    itemId,
  } = props;
  const { data: productData } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
    },
    skip: !itemId,
    ssr: false,
  });
  const isPackage = isPackageRecommendationFlag(productData);
  const [isPMVEnabled, setIsPMVEnabled] = useState(false);
  const [isFBTEnabled, setIsFBTEnabled] = useState(false);
  const [isPMVAndFBTEnabled, setIsPMVAndFBTEnabled] = useState(false);
  const fsRecsFBT = useConfigService('fs:isFbtRecsEnable');
  const abcTestEnable = useConfigService('fs:isABCTestEnable');
  const hasOneActiveABCTest = [isPMVEnabled, isFBTEnabled, isPMVAndFBTEnabled].filter((val) => !!val).length === 1;

  useEffect(() => {
    shouldDisplayFBTPMV(setIsPMVEnabled, setIsFBTEnabled, setIsPMVAndFBTEnabled);
  }, []);

  const { product } = productData || {};
  const availableProduct = isProductAvailable(product, abcTestEnable);
  if (!availableProduct) {
    return null;
  }
  const packagesMiniVisual = (
    <RenderPackagesMiniVisual itemId={itemId} />
  );
  const thdRecsFbt = <RenderThdRecsFBT itemId={itemId} />;
  const frequentlyBoughtTogether = <RenderFrequentlyBoughtTogether itemId={itemId} />;

  const shouldDisplayRecs = !shouldDisplayFbtbundle(productData)
    && shouldDisplayRecsFBT(productData, referer) && fsRecsFBT;
  const shouldDisplayPipInStocks = !shouldDisplayPipInStock(productData, referer);
  const renderFBT = (
    shouldDisplayRecs ? (
      <>
        {thdRecsFbt}
      </>
    ) : (
      <>
        {shouldDisplayPipInStocks && frequentlyBoughtTogether}
      </>
    )
  );

  return (
    <>
      {abcTestEnable && isPackage ? (
        <>
          {/* There is no `or` case on purpose for nothing to render when the test is active but no variant enabled */}
          {hasOneActiveABCTest && (
            <>
              {isPMVEnabled && (
                <>
                  {packagesMiniVisual}
                </>
              )}
              {isFBTEnabled && (
                <>
                  {renderFBT}
                </>
              )}
              {isPMVAndFBTEnabled && (
                <>
                  {packagesMiniVisual}
                  {renderFBT}
                </>
              )}
            </>
          )}
        </>
      )
        : (
          <>
            {
              isPackage ? (
                <>
                  {packagesMiniVisual}
                </>
              ) : (
                <>
                  {renderFBT}
                </>
              )
            }
          </>
        )}
    </>
  );
};

RenderPackagesMiniVisual.propTypes = {
  itemId: stringType,
};

RenderFrequentlyBoughtTogether.propTypes = {
  itemId: stringType,
};

RenderThdRecsFBT.propTypes = {
  itemId: stringType,
};

PIPFbtAndPmvLayout.propTypes = {
  itemId: stringType,
  // eslint-disable-next-line
  productData: object,
};

RenderPackagesMiniVisual.defaultProps = {
  itemId: '',
};

RenderFrequentlyBoughtTogether.defaultProps = {
  itemId: '',
};

RenderThdRecsFBT.defaultProps = {
  itemId: '',
};

PIPFbtAndPmvLayout.defaultProps = {
  itemId: '',
  productData: {},
};

PIPFbtAndPmvLayout.dataModel = extend(
  pipRecsModel,
  FrequentlyBoughtTogether,
  PackagesMiniVisual,
  ThdRecsFbt,
  podFulFillmentUtils
);

PIPFbtAndPmvLayout.displayName = 'PIPFbtAndPmvLayout';