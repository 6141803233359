import React, { cloneElement } from 'react';
import { QueryProvider } from '../react/QueryProvider';

/**
 *
 * @param {*} WrappedComponent
 * @param {*} props QueryProvider props
 */
export const withQueryProvider = (WrappedComponent, props) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const WrappedClone = (wrappedProps) => <WrappedComponent {...wrappedProps} />;
  WrappedClone.wraps = WrappedComponent.wraps || {};
  WrappedClone.dataModel = WrappedComponent.dataModel;
  WrappedClone.propTypes = WrappedComponent.propTypes;
  WrappedClone.displayName = WrappedComponent.displayName;
  WrappedClone.defaultProps = WrappedComponent.defaultProps;
  const Component = ({ children, ...wrappedComponentProps }) => {

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <QueryProvider {...props}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <WrappedClone {...wrappedComponentProps}>
          {children}
        </WrappedClone>
      </QueryProvider>
    );
  };
  Component.propTypes = WrappedComponent.propTypes;
  Component.defaultProps = WrappedComponent.defaultProps;

  Component.displayName = WrappedComponent.displayName || 'QueryProvider';
  Component.wraps = {
    ...(WrappedComponent.wraps || {}),
    queryProvider: true,
  };

  return Component;
};