/* eslint-disable tailwindcss/no-custom-classname */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { params, shape, string, extend } from '@thd-nucleus/data-sources';
import { StickyNav } from '../component/StickyNav.component';

export const PIPStickyNav = ({ itemId, hideRatingsIfHDPPSku, isHDPPSku }) => {
  const setExpandedRef = useRef();
  const handleClick = (target, index) => {
    if (setExpandedRef.current) {
      // event is undefined here for the accordion
      setExpandedRef.current(index)(undefined, true);
    }
    window.LIFE_CYCLE_EVENT_BUS.trigger('sticky-nav.click', { target });
  };

  const ratingsRef = useRef();
  const productOverviewRef = useRef();
  const specificationsRef = useRef();
  const questionsRef = useRef();
  const stickyNavRef = useRef();

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('accordion.mount', ({ output }) => {

      const { wrapperIds, setExpanded } = output;
      setExpandedRef.current = setExpanded;
      productOverviewRef.current = document.querySelector('#' + wrapperIds[0]);
      specificationsRef.current = document.querySelector('#' + wrapperIds[1]);
      questionsRef.current = document.querySelector('#' + wrapperIds[2]);
      ratingsRef.current = document.querySelector('#' + wrapperIds[3]);
    });

  }, []);

  return (
    <StickyNav
      itemId={itemId}
      ref={stickyNavRef}
      ratingsRef={isHDPPSku ? null : ratingsRef}
      onRatingsClick={() => handleClick('#product-section-rr', false)}
      hideIfHDPPSku={hideRatingsIfHDPPSku}
    >
      <StickyNav.Links>
        <StickyNav.Link targetRef={productOverviewRef}>
          <div
            className="navlink-pso"
            onClick={() => handleClick('#product-section-overview', 0)}
            role="button"
            tabIndex={0}
          >Product Details
          </div>
        </StickyNav.Link>
        <StickyNav.Link targetRef={specificationsRef}>
          <div
            className="navlink-specs"
            onClick={() => handleClick('#product-section-key-feat', 1)}
            role="button"
            tabIndex={0}
          >Specifications
          </div>
        </StickyNav.Link>
        <StickyNav.Link targetRef={questionsRef}>
          <div
            className="navlink-qa"
            onClick={() => handleClick('#product-section-qa', 2)}
            role="button"
            tabIndex={0}
          >Questions & Answers
          </div>
        </StickyNav.Link>
        {!isHDPPSku
          && (
            <StickyNav.Link targetRef={ratingsRef}>
              <div
                className="navlink-rr"
                onClick={() => handleClick('#product-section-rr', 3)}
                role="button"
                tabIndex={0}
              >Customer Reviews
              </div>
            </StickyNav.Link>
          )}
      </StickyNav.Links>
    </StickyNav>
  );
};

PIPStickyNav.displayName = 'PIPStickyNav';

PIPStickyNav.propTypes = {
  itemId: PropTypes.string.isRequired,
  hideRatingsIfHDPPSku: PropTypes.bool,
  isHDPPSku: PropTypes.bool
};

PIPStickyNav.dataModel = extend({}, {
  product: params({ itemId: string().isRequired() }).shape({
    identifiers: shape({
      skuClassification: string(),
    })
  }),
}, StickyNav);

PIPStickyNav.defaultProps = {
  hideRatingsIfHDPPSku: true,
  isHDPPSku: false
};