/* eslint-disable react/prop-types */
import React, {
  useRef, useCallback, useContext, useEffect, useState
} from 'react';
import {
  bool as boolType,
  func,
  string as stringType,
  number as numberType,
  shape
} from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { useStore, ExperienceContext } from '@thd-nucleus/experience-context';
import { Typography } from '@one-thd/sui-atomic-components';
import { ImpressionProvider } from '@thd-olt-component-react/impression';
import className from 'classnames';
import { dynamicRecsDataModel } from '../dataModel';
import { isLoading, getAppId, getSkippers, impressionContainerNameParser } from '../utils/helpers';
import { ProductPodV7Carousel } from '../core/ProductPodV7Carousel';
import { CollectionCarousel } from '../core/CollectionCarousel';
import '../../styles/thd-recs-containers.scss';

let obj = null;
let isTNT = false;
if (typeof LIFE_CYCLE_EVENT_BUS !== 'undefined') {
  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('recs.tnt', (data) => {
    isTNT = data?.eventName === 'tnt';
    obj = data?.output;
  });
}

const DynamicRecs = (props) => {
  const { storeId, storeZip: zipCode, isLocalized, membershipInformation } = useStore();
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const loadedStoreInfo = useRef({ storeId, zipCode });
  const batteryTabName = useRef([]);
  const {
    anchorId,
    anchorImage,
    schemaName,
    showLoading,
    serviceType,
    showColUpLevel,
    isBatteryCollection,
    isCollection,
    maxResults,
    requestKey,
    hideSwatches,
    hideATC,
    hideFavorites,
    checkMinimumProducts,
    parent,
    zoneName,
    zonePosition,
    itemIds,
    impressionData,
    miniumumProductCount,
    browseNValue
  } = props;

  const [newCategoryGuid, setNewCategoryGuid] = useState(anchorId);
  const tntSchema = obj?.originalScheme === schemaName && obj?.replacedScheme;

  const opts = {
    variables: {
      anchorId: newCategoryGuid,
      storeId,
      key: requestKey,
      zipCode: zipCode || '12345',
      apiName: (isTNT && tntSchema) ? tntSchema : schemaName,
      skipInstallServices: true,
      dataSource: schemaName,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
      serviceType,
      maxResults,
      appId: getAppId(channel, isConsumerApp),
      nvalue: browseNValue,
    },
    skip: !isLocalized,
    ssr: false
  };

  const {
    data, variables, loading, error, refetch
  } = useDataModel('recs', opts);

  useEffect(() => {
    if (itemIds && itemIds?.length > 0) {
      refetch();
    }
  }, [itemIds]);

  if (data && !loading && isLocalized) {
    loadedStoreInfo.current = {
      storeId: variables.storeId,
      zipCode: variables.zipCode,
      mounted: true
    };
  }
  // mounted is when you want it to fetch client() only fields
  // such  as fulfillment. You want to use this flag
  // for inner queries to not change to localized values
  // storeId, zip, until the outer query has resolved
  const mountedFn = useCallback(
    () => loadedStoreInfo.current.mounted,
    [loadedStoreInfo.current.mounted]
  );

  const setCategoryGuid = useCallback(
    (event, guid, categoryName, changeActiveIndex, index) => {
      event.preventDefault();
      batteryTabName.current = categoryName;
      changeActiveIndex(index);
      setNewCategoryGuid(anchorId + guid);
    },
    []
  );

  const wrapperClasses = className(
    'dynamic-recs',
    'sui-p-4',
    schemaName,
    {
      loading: showLoading && loading,
    }
  );

  if (checkMinimumProducts && data?.recs?.products?.length < miniumumProductCount) {
    return null;
  }

  if (anchorId === newCategoryGuid && (error || (data !== undefined && (!data || !data?.recs) && !loading))) {
    if ((schemaName === 'PLP_Search_In_Stock' || schemaName === 'PLP_Browse_In_Stock')) {
      return (
        <Typography variant="body-base" component="h4">
          <div className="sui-flex sui-justify-center sui-items-center">
            No similar items found
          </div>
        </Typography>
      );
    }
    return null;
  }

  const anchorIdForAnalytics = anchorId?.split(',')[0];

  return (
    <ImpressionProvider
      data={{
        id: impressionData?.id || '',
        component: impressionData?.component || '',
        name: impressionContainerNameParser(data?.recs?.metadata?.title || null),
        type: impressionData?.type || '',
      }}
    >
      <div
        id={schemaName}
        className={wrapperClasses}
        data-type="container"
        data-component={`DynamicRecs-${schemaName}`}
      >
        <meta data-prop="name" content={schemaName} />
        {isCollection
          ? (
            <CollectionCarousel
              anchorId={anchorId}
              anchorImage={anchorImage}
              itemClass="fixed-item"
              showColUpLevel={showColUpLevel}
              scheme={schemaName}
              isBatteryCollection={isBatteryCollection}
              tabClick={batteryTabName?.current}
              setCategoryGuid={setCategoryGuid}
              loading={isLoading(data, loading)}
              data={data?.recs}
              hideSwatches={hideSwatches}
              hideATC={hideATC}
              hideFavorites={hideFavorites}
              storeId={loadedStoreInfo.current.storeId}
              membershipInformation={membershipInformation}
              zipCode={loadedStoreInfo.current.zipCode}
              parent="recs-product-pod-carousel"
              analyticsAnchorProductSku={anchorIdForAnalytics}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )
          : (
            <ProductPodV7Carousel
              anchorId={anchorId}
              anchorImage={anchorImage}
              itemClass="fixed-item"
              scheme={schemaName}
              loading={isLoading(data, loading)}
              data={data?.recs}
              hideSwatches={hideSwatches}
              hideATC={hideATC}
              hideFavorites={hideFavorites}
              storeId={loadedStoreInfo.current.storeId}
              membershipInformation={membershipInformation}
              zipCode={loadedStoreInfo.current.zipCode}
              parent={parent || 'recs-product-pod-carousel'}
              zoneName={zoneName}
              zonePosition={zonePosition}
              podResults={data?.recs?.products?.length}
              analyticsAnchorProductSku={anchorIdForAnalytics}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
      </div>
    </ImpressionProvider>

  );
};

const dataModel = dynamicRecsDataModel;
DynamicRecs.dataModel = dataModel;

DynamicRecs.displayName = 'DynamicRecsComponent';
const propTypes = {
  anchorId: stringType.isRequired,
  anchorImage: stringType,
  showColUpLevel: boolType,
  isBatteryCollection: boolType,
  isCollection: boolType,
  hideFavorites: boolType,
  maxItems: numberType,
  schemaName: stringType.isRequired,
  serviceType: stringType,
  showLoading: boolType,
  maxResults: stringType,
  browseNValue: stringType,
  requestKey: stringType,
  hideATC: boolType,
  checkMinimumProducts: boolType,
  zoneName: stringType,
  zonePosition: stringType,
  itemIds: stringType,
  impressionData: shape({
    id: stringType,
    component: stringType,
    type: stringType
  }),
  miniumumProductCount: numberType
};

DynamicRecs.propTypes = propTypes;

const defaultProps = {
  anchorImage: null,
  showColUpLevel: false,
  isCollection: false,
  isBatteryCollection: false,
  hideFavorites: true,
  maxItems: 16,
  serviceType: null,
  maxResults: null,
  browseNValue: null,
  showLoading: true,
  hideATC: false,
  checkMinimumProducts: false,
  requestKey: 'aGAQFG4j6QtVTSWqujfFYWeIU6BR5Mee',
  zoneName: '',
  itemIds: '',
  zonePosition: '',
  impressionData: {
    id: null,
    component: null,
    type: null
  },
  miniumumProductCount: 6
};

DynamicRecs.defaultProps = defaultProps;

DynamicRecs.propTypes = propTypes;
DynamicRecs.defaultProps = defaultProps;
DynamicRecs.dataModel = dataModel;

export default DynamicRecs;
