import React, { useEffect } from 'react';
import { string, bool } from 'prop-types';
import {
  extend,
  params,
  shape as shapeType,
  bool as boolType,
  string as stringType
} from '@thd-nucleus/data-sources';
import { useDataModel } from '@thd-nucleus/data-sources/react/dataModel/useDataModel';
import { Col, Image } from '@thd-olt-component-react/core-ui';
import './returns-messaging.scss';
import { ReturnsMessagingPlaceholder } from './ReturnsMessagingPlaceholder';

const ReturnsMessaging = ({ itemId, displayTimeframe }) => {
  const ICON_HEIGHT = '25';
  const ICON_WIDTH = '32';

  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('returns-messaging.ready'); }, []);

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  if (loading && !data) {
    return (
      <Col className="returns-messaging" data-component="ReturnsMessagingPlaceholder">
        <ReturnsMessagingPlaceholder />
      </Col>
    );
  }

  if (
    (error && !data)
    || data?.product?.info?.returnable === 'Non-Returnable'
    || typeof data?.product?.info?.returnable !== 'string'
  ) {
    return null;
  }

  const { returnable, isInStoreReturnMessageEligible } = data.product.info;
  const hasTimeframe = /[0-9]+-\w+/.test(returnable);
  const returnMessage = returnable.replace('-', ' ').toLowerCase() + 's';

  return (
    <>
      {displayTimeframe && hasTimeframe && (
        <Col className="returns-messaging" data-component="ReturnsMessaging">
          <div className="returns-messaging__wrapper">
            <Image
              asset="returns.svg"
              alt="icon"
              className="returns-messaging__icon"
              height={ICON_HEIGHT}
              width={ICON_WIDTH}
            />
            <div id="returnsMessaging">
              <div className="returns-messaging__title">
                {`Free & Easy Returns In Store ${isInStoreReturnMessageEligible ? '' : 'or Online'}`}
              </div>
              <div className="returns-messaging__text">
                <span>
                  Return this item within {' '}
                  <span className="returns-messaging__date">{returnMessage}</span>
                  {' '}of purchase.{' '}
                </span>
                <a
                  href="https://www.homedepot.com/c/Return_Policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="returns-messaging__link"
                >
                  Read Return Policy
                </a>
              </div>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

ReturnsMessaging.displayName = 'ReturnsMessaging';

ReturnsMessaging.dataModel = extend({
  product: params({ itemId: stringType().isRequired() }).shape({
    itemId: stringType(),
    dataSources: stringType(),
    info: shapeType({
      returnable: stringType(),
      isInStoreReturnMessageEligible: boolType()
    })
  }),
});

ReturnsMessaging.propTypes = {
  itemId: string.isRequired,
  displayTimeframe: bool,
};

ReturnsMessaging.defaultProps = {
  displayTimeframe: false,
};

export { ReturnsMessaging };
