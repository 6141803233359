import React from 'react';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { Typography } from '@one-thd/sui-atomic-components';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';

export const ProductOverviewAccordionTitleComponent = () => {
  return (
    <>
      <div
        data-component="ProductOverviewAccordionTitle"
        className="sui-h-10 sui-flex sui-flex-col sui-justify-center"
      >
        <Typography
          height="tight"
          weight="bold"
          variant="h5"
          component="h3"
        >
          Product Details
        </Typography>
      </div>
    </>
  );
};

ProductOverviewAccordionTitleComponent.displayName = 'ProductOverviewAccordionTitleComponent';

const DynamicComponent = withDynamicComponent(ProductOverviewAccordionTitleComponent);
const ProductOverviewAccordionTitle = withErrorBoundary(DynamicComponent);

ProductOverviewAccordionTitle.displayName = 'ProductOverviewAccordionTitle';

export { ProductOverviewAccordionTitle };
