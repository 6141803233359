const NOT_APPLICABLE = 'n/a';

export const fbtAddToCartAnalytics = (payload = {}) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    const {
      metadata = {},
      bundle = false,
      analyticsItems: items = []
    } = payload;

    const {
      modelName = NOT_APPLICABLE,
      version = NOT_APPLICABLE,
      apiName = NOT_APPLICABLE
    } = metadata;

    const output = {
      bundle,
      items,
      modelName,
      version,
      apiName
    };

    window.LIFE_CYCLE_EVENT_BUS.trigger('fbt.add-to-cart', output);
  }

  return null;
};

export const track = (opts) => {
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('fbt.click', opts);
  }
};
