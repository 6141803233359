import { useState, useEffect } from 'react';
import { EVENTS } from '../constants';

export const useDynamicSlots = () => {
  const [data, setData] = useState({
    loaded: false,
    response: {},
  });
  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on(EVENTS.SDUI_FETCH, (evt) => {
      setData({
        loaded: true,
        response: evt.output,
      });
    });
  }, []);

  return data;
};