import React, { useContext } from 'react';
import { Placeholder } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';

const ReturnsMessagingPlaceholder = () => {
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  return (
    <Placeholder
      height={isMobile ? '42px' : '32px'}
      type="text"
    />
  );
};

export { ReturnsMessagingPlaceholder };