import React, { useContext, useEffect, useRef } from 'react';
import {
  arrayOf as arrayOfType, bool as boolType, client,
  extend, number as numberType,
  params, shape as shapeType, string as stringType, useLazyDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import { SizeGuide } from '@thd-olt-component-react/size-guide';
import { useDomPath } from '@thd-olt-functional/utils';
import {
  bool, func, number, string
} from 'prop-types';

import { Alerts } from './components/alerts/alerts';
import { Loader } from './components/loader/loader';
import { LoadingError } from './components/loading-error/loading-error';
import { MiniSwatchTooltip } from './components/mini-swatch-tooltip/mini-swatch-tooltip';
import { MoreOptions } from './components/more-options/more-options';
import { SuperDuperSku } from './components/super-duper-sku/super-duper-sku';
import { isSdskuDisplayable } from './components/super-duper-sku/super-duper-sku-utils';
import { SuperSkuAttributeValue } from './components/super-sku-attribute-value/super-sku-attribute-value';
import { SuperSkuAttribute } from './components/super-sku-attribute/super-sku-attribute';
import { SuperSkuCarousel } from './components/super-sku-carousel/SuperSkuCarousel';
import {
  attributeValueFilter,
  getAttributeType,
  getSelectedAttribute,
  getAttributeWidth,
  useSuperSku
} from './hooks/useSuperSku';
import { publish } from './publisher';
import {
  ACTION_TYPES, MAXIMUM_CALLS_FOR_PRICE_ENDPOINT,
  MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT, NUMBER_OF_MINI_SWATCHES_ON_DESKTOP,
  NUMBER_OF_MINI_SWATCHES_ON_MOBILE
} from './statics';
import {
  getSwatchCardCombinationDetails,
  shouldDisplayInventory,
  shouldDisplaySwatchCards,
  shouldDisplayTooltips,
  syncAttributes,
  rgbToHex
} from './super-sku-util';
import './super-sku.scss';

export { CollectionsSuperSku } from './components/collections-super-sku/collections-super-sku';
export { SuperSkuMiniSwatches } from './components/super-sku-mini-swatches/super-sku-mini-swatches';

export const SuperSku = (props) => {
  const {
    channel = 'desktop'
  } = useContext(ExperienceContext);

  const defaultStoreId = useStoreId();
  const {
    centeredCards,
    disableMediaAndAvailability,
    hideCards,
    itemId,
    configId,
    large,
    miniSwatch,
    numberOfMiniSwatches,
    onChange,
    onHover,
    shouldSyncAttributes,
    storeId: storeIdFromProps,
    isPaintReorder,
    containerSizeHref
  } = props;

  const storeId = storeIdFromProps || defaultStoreId;

  const [state, dispatch] = useSuperSku({
    itemId,
    configId,
    storeId,
    disableMediaAndAvailability,
    isMiniSwatch: miniSwatch,
    large
  });

  const disableAvailability = disableMediaAndAvailability || isPaintReorder || state?.info?.dotComColorEligible;
  const largeCard = large || isPaintReorder || state?.info?.dotComColorEligible;
  const centeredContent = centeredCards || isPaintReorder || state?.info?.dotComColorEligible;
  const requestIdleCallbackRef = useRef(null);
  const [domPath, ref] = useDomPath();

  const triggerSuperSkuClickAnalytics = (attributeLabel, attributeValue) => {
    if (shouldSyncAttributes) {
      const { parentId } = state;
      publish('pass-to-collection', { attributeLabel, attributeValue, parentId });
    }
    publish('click-swatch', { attributeLabel, attributeValue, path: domPath });
  };

  const handleAttributeValueClick = (event, attributeLabel, attributeValue) => {
    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => { triggerSuperSkuClickAnalytics(attributeLabel, attributeValue); });
    } else {
      triggerSuperSkuClickAnalytics(attributeLabel, attributeValue);
    }
    dispatch({
      type: ACTION_TYPES.SELECT, attributeLabel, attributeValue
    });
  };

  const handleAttributeValueHover = (
    event, attributeLabel, attributeValue, isTooltipsEnabled, isMiniSwatch, attributeType
  ) => {
    if (!isTooltipsEnabled && !isMiniSwatch && attributeType === 'dropdown') return;
    dispatch({
      type: ACTION_TYPES.HOVER, attributeLabel, attributeValue
    });
  };

  const handleResetHover = () => {
    dispatch({
      type: ACTION_TYPES.HOVER_RESET
    });
  };

  const handleAttributeOverflowReset = (scrollHeight, attributeName) => {
    dispatch({
      type: ACTION_TYPES.RESET_OVERFLOW_VALUES, scrollHeight, attributeName
    });
  };

  const handleAttributeOverflowUpdate = (attributeRef, attributeName) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_OVERFLOW_VALUES, attributeRef, attributeName
    });
  };

  const handleSdskuAttributeValueClick = (metadata) => {
    dispatch({
      type: ACTION_TYPES.SDSKU_SELECT, metadata
    });
    publish('click-super-duper-sku', { path: domPath });
  };

  const handleSdskuAttributeOverflowUpdate = (attributeRef, attributeName) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SDSKU_OVERFLOW_VALUES,
      attributeRef,
      attributeName
    });
  };

  const handleSdskuAttributeOverflowReset = (scrollHeight, attributeName) => {
    dispatch({
      type: ACTION_TYPES.RESET_SDSKU_OVERFLOW_VALUES,
      scrollHeight,
      attributeName
    });
  };

  const onReloadClick = () => {
    const { location = {} } = window || {};
    location.reload(true);
  };

  let itemIds = state.childItemsLookup?.map((item) => item?.itemId);
  const opts = {
    variables: {
      itemIds,
      storeId
    },
    ssr: false
  };
  const [makeMediaInventoryCall, mediaPriceInventoryData] = useLazyDataModel('mediaPriceInventory', opts);
  const shouldMakeCallMediaCall = disableAvailability
    || !itemIds?.length;

  useEffect(() => {
    if (!shouldMakeCallMediaCall) {
      const itemSubset = itemIds.slice(
        0, MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT
      );
      if (itemSubset.length) {
        makeMediaInventoryCall({
          variables: {
            itemIds: itemSubset
          }
        });
      }
    }
  }, [shouldMakeCallMediaCall, state?.parentId]);

  const { mediaPriceInventory } = mediaPriceInventoryData?.data || {};

  useEffect(() => {
    if (!mediaPriceInventoryData?.called) return;
    if (mediaPriceInventory
      && (state.childItemsLookup || []).length === mediaPriceInventory?.productDetailsList?.length) {
      dispatch({
        type: ACTION_TYPES.ADD_MEDIA_PRICE_AND_INVENTORY,
        response: mediaPriceInventory,
        channel
      });
    } else if (mediaPriceInventory
      && (state.childItemsLookup || []).length > mediaPriceInventory?.productDetailsList?.length) {
      const itemSubset = itemIds.slice(
        MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT,
        MAXIMUM_COMBINATIONS_FOR_PRICE_ENDPOINT * MAXIMUM_CALLS_FOR_PRICE_ENDPOINT
      );
      if (itemSubset.length) {
        mediaPriceInventoryData.fetchMore({ // need to validate this still works
          variables: {
            itemIds: itemSubset
          },
          updateQuery: (currentItems, { fetchMoreResult: nextBatch }) => {
            if (!nextBatch) return currentItems;
            const { productDetailsList = [], __typename } = nextBatch?.mediaPriceInventory || {};
            const newItems = productDetailsList.filter(({ itemId: newItemId }) => {
              return !(currentItems?.mediaPriceInventory?.productDetailsList.find((item) => item.itemId === newItemId));
            });
            return {
              mediaPriceInventory: {
                productDetailsList: [
                  ...currentItems?.mediaPriceInventory?.productDetailsList,
                  ...newItems
                ],
                __typename
              }
            };
          }
        });
      }
    }
  }, [mediaPriceInventory]);

  useEffect(() => {
    if (state?.itemId && state?.itemId !== itemId) {
      const [, , slug] = state?.canonicalUrl?.split('/');
      onChange({ slug, itemId: state?.itemId, canonicalUrl: state.canonicalUrl });
      window.LIFE_CYCLE_EVENT_BUS.trigger('super-sku.change', { itemId, slug, canonicalUrl: state.canonicalUrl });
    }
  }, [state?.itemId]);

  useEffect(() => {
    const { hoveredCombinationDetails = {}, itemId: stateItemId } = state || {};

    if (Object.keys(hoveredCombinationDetails).length > 0) {
      const { itemId: hoveredDetailsItemId, isHovered } = hoveredCombinationDetails;

      onHover({
        itemId: hoveredDetailsItemId || stateItemId,
        isHovered
      });
      if (typeof window !== 'undefined') {
        window.LIFE_CYCLE_EVENT_BUS.trigger('supersku.hover', hoveredDetailsItemId);
      }
    }
  }, [state?.hoveredCombinationDetails, state?.hoveredCombinationDetails?.itemId]);

  useEffect(() => {
    if (ref?.current) {
      if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS && !largeCard) {
        window.LIFE_CYCLE_EVENT_BUS.trigger('super-sku.ready', {
          height: ref?.current?.scrollHeight
        });
      }
    }

  }, [ref.current]);

  useEffect(() => {
    if (domPath?.length > 0 && state?.parentId) {
      syncAttributes(state, dispatch, domPath);
    }
  }, [domPath?.length, state?.parentId]);

  const updateProductCallback = async () => {
    await dispatch({
      type: ACTION_TYPES.SELECTED_PRODUCT
    });

    const tippyPopper = document.querySelector('.tippy-popper');
    if (tippyPopper && tippyPopper._tippy) {
      tippyPopper._tippy.hide();
    }
  };

  useEffect(() => {
    if (state.isSskuClicked) {
      if ('requestIdleCallback' in window) {
        if (requestIdleCallbackRef?.current && 'cancelIdleCallback' in window) {
          cancelIdleCallback(requestIdleCallbackRef.current);
        }
        requestIdleCallbackRef.current = window.requestIdleCallback(updateProductCallback, { timeout: 3000 });
      } else {
        updateProductCallback();
      }
    }
  }, [state.isSskuClicked]);

  if (state?.loadingState && !miniSwatch) {
    return <Loader large={largeCard} />;
  }

  const shouldShowAttribute = (attribute) => {
    const { isSwatch, attributeValues } = attribute;
    return attributeValues.length > 1
      && (!miniSwatch || (miniSwatch && isSwatch));
  };

  const hasSwatch = (attributes) => {
    return !!attributes.find(({ isSwatch }) => isSwatch);
  };

  const {
    attributes,
    brandName,
    canonicalUrl,
    childItemsLookup,
    collectionId,
    enabledAndAvailableAttributeValues,
    errorState,
    hoveredCombinationDetails,
    impossibleState,
    isTooltipsEnabled,
    itemSizeAndFitDetail,
    info,
    jumpedState,
    noDataState,
    parentId,
    superDuperSku,
    superSkuSizeAndFitDetail,
    showAlerts,
    specificationGroup,
    selectedAttributes,
    itemId: itemIdFromState,
    paintDetails,
  } = state;

  const { dotComColorEligible = false } = info || {};

  if (attributes?.[1]?.attributeName === 'Fabric Type') {
    attributes.unshift(...attributes.splice(1, 1));
    state.selectedAttributes.unshift(...state.selectedAttributes.splice(1, 1));
  }

  const shouldHideSSKU = attributes?.filter(shouldShowAttribute)?.length === 0;
  if (noDataState || !attributes?.length || shouldHideSSKU) {
    return null;
  }

  const sizeGuideInfo = {
    collectionId,
    itemId,
    itemSizeAndFitDetail,
    parentId,
    superSkuSizeAndFitDetail
  };

  const numberOfMiniSwatchesToDisplay = numberOfMiniSwatches || channel === 'desktop'
    ? NUMBER_OF_MINI_SWATCHES_ON_DESKTOP
    : NUMBER_OF_MINI_SWATCHES_ON_MOBILE;

  // Paint experience
  let hexColor;
  if (paintDetails?.rgb) {
    hexColor = rgbToHex(paintDetails.rgb);
  } else {
    hexColor = (dotComColorEligible && specificationGroup?.find(
      (group) => group?.specTitle === 'Details')?.specifications?.find(
      (spec) => /hexadecimal/i.test(spec?.specName))?.specValue) || '';
  }

  const isMobile = channel === 'mobile';
  const paintType = dotComColorEligible
    ? selectedAttributes.find((item) => item?.attributeLabel === 'Paint Type')?.attributeValue : '';

  return (
    <div className="super-sku" ref={ref} data-component="SuperSku">
      {showAlerts && (
        <Alerts
          impossibleState={impossibleState}
          jumpedState={jumpedState}
        />
      )}
      {!errorState
        ? (attributes || []).filter(shouldShowAttribute).map((attribute, attributeIndex) => {
          const { attributeName, attributeValues, isOverflowSection, scrollHeight } = attribute;
          const isHovered = state?.hoveredCombinationDetails?.isHovered;
          const selectedAttribute = getSelectedAttribute(state, attributeName, attributeValues);
          const attributeType = getAttributeType(
            state,
            channel,
            attribute,
            isOverflowSection,
            hideCards,
            disableAvailability,
            largeCard,
            dotComColorEligible
          );
          const attributeWidth = getAttributeWidth(attributeType, miniSwatch);
          const enabledTilesInAttribute = enabledAndAvailableAttributeValues?.enabled[attributeName];
          const availableTilesInAttribute = enabledAndAvailableAttributeValues?.available[attributeName];
          const maxItemsToShow = miniSwatch ? numberOfMiniSwatchesToDisplay : attributeValues.length;

          const { attributeValue: selectedAttributeValue, swatchGuid: selectedSwatchGuid } = selectedAttribute || {};

          return (attributeType
            && (
              <SuperSkuAttribute
                attributeName={attributeName}
                attributeValue={isHovered
                  ? hoveredCombinationDetails[attributeName] : selectedAttributeValue}
                swatchGuid={selectedSwatchGuid}
                attributeType={attributeType}
                handleAttributeOverflowReset={handleAttributeOverflowReset}
                handleAttributeOverflowUpdate={handleAttributeOverflowUpdate}
                hexColor={hexColor}
                isOverflowSection={isOverflowSection}
                info={info}
                key={`${attributeName}-${attributeIndex}`}
                isPaintReorder={isPaintReorder}
                large={largeCard}
                miniSwatch={miniSwatch}
                scrollHeight={scrollHeight}
                sizeGuideInfo={sizeGuideInfo}
                paintType={paintType}
                brandName={brandName}
                itemId={itemIdFromState}
                containerSizeHref={containerSizeHref}
              >
                <SuperSkuCarousel
                  shouldUseCarousel={largeCard && !isMobile}
                  carouselProps={{
                    name: 'super-sku-carousel',
                    multiItem: false,
                    itemClass: 'super-sku-item',
                    fade: true,
                    itemWidthPixelsDesktop: attributeWidth,
                    fixedItem: true,
                    screenBreakpoint: 550,
                    screenMaxSize: 550,
                  }}
                >
                  {attributeValues
                    .filter((attributeValue) => attributeValueFilter({
                      attributeValue,
                      attribute,
                      enabledTilesInAttribute
                    }))
                    .slice(0, maxItemsToShow)
                    .map((attributeValue) => {
                      const { value, swatchGuid } = attributeValue;
                      const isAvailable = (shouldDisplayInventory(state))
                        ? availableTilesInAttribute?.has(value)
                        : attribute.isAvailable;
                      const swatchCardCombinationDetails = (attributeType === 'card')
                        ? getSwatchCardCombinationDetails(state, attributeName, value) : {};
                      return (
                        <SuperSkuAttributeValue
                          attributeType={attributeType}
                          channel={channel}
                          centeredCards={centeredContent}
                          hexColor={hexColor}
                          paintBrand={info?.paintBrand}
                          hoveredCombinationDetails={hoveredCombinationDetails || {}}
                          isAvailable={isAvailable}
                          isFabricType={attributeName === 'Fabric Type'}
                          isDotComColorEligible={dotComColorEligible}
                          isOverflowSection={isOverflowSection}
                          isSelected={selectedAttributeValue === value}
                          isTooltipsEnabled={isTooltipsEnabled}
                          key={`${attributeName}-${value}`}
                          miniSwatch={miniSwatch}
                          onClick={(event) => handleAttributeValueClick(
                            event,
                            attributeName,
                            value
                          )}
                          onMouseEnter={((event) => handleAttributeValueHover(event,
                            attributeName,
                            value,
                            isTooltipsEnabled,
                            miniSwatch,
                            attributeType
                          ))}
                          onMouseLeave={handleResetHover}
                          swatchCardCombinationDetails={swatchCardCombinationDetails}
                          swatchGuid={swatchGuid}
                          value={value}
                          attributeName={attributeName}
                          isPaintReorder={isPaintReorder}
                        />
                      );
                    })}
                  {miniSwatch && attributeValues.length > maxItemsToShow && (
                    <MiniSwatchTooltip
                      attributeName={attributeName}
                      attributeType={attributeType}
                      attributeValues={attributeValues}
                      canonicalUrl={canonicalUrl}
                      channel={channel}
                      handleAttributeValueClick={handleAttributeValueClick}
                      handleAttributeValueHover={handleAttributeValueHover}
                      handleResetHover={handleResetHover}
                      hoveredCombinationDetails={hoveredCombinationDetails}
                      isOverflowSection={isOverflowSection}
                      isTooltipsEnabled={isTooltipsEnabled}
                      miniSwatch={miniSwatch}
                      selectedAttribute={selectedAttributeValue}
                      parentId={parentId}
                      itemId={itemId}
                    />
                  )}
                </SuperSkuCarousel>
              </SuperSkuAttribute>
            ));
        })
        : (<LoadingError onReloadClick={onReloadClick} />)}
      {miniSwatch
        && !hasSwatch(attributes)
        && canonicalUrl
        && <MoreOptions canonicalUrl={canonicalUrl} />}
      {superDuperSku && !miniSwatch && isSdskuDisplayable(superDuperSku)
        && (
          <SuperDuperSku
            channel={channel}
            handleSdskuAttributeValueClick={handleSdskuAttributeValueClick}
            handleSdskuAttributeOverflowReset={handleSdskuAttributeOverflowReset}
            handleSdskuAttributeOverflowUpdate={handleSdskuAttributeOverflowUpdate}
            superDuperSku={superDuperSku}
          />
        )}
    </div>
  );
};

SuperSku.displayName = 'SuperSku';

SuperSku.propTypes = {
  centeredCards: bool,
  disableMediaAndAvailability: bool,
  hideCards: bool,
  itemId: string.isRequired,
  configId: string,
  large: bool,
  miniSwatch: bool,
  numberOfMiniSwatches: number,
  onChange: func,
  onHover: func,
  shouldSyncAttributes: bool,
  storeId: string,
  isPaintReorder: bool,
  containerSizeHref: string
};

SuperSku.defaultProps = {
  centeredCards: false,
  disableMediaAndAvailability: false,
  hideCards: false,
  configId: undefined,
  large: false,
  miniSwatch: false,
  numberOfMiniSwatches: null,
  onChange: () => { },
  onHover: () => { },
  shouldSyncAttributes: false,
  storeId: null,
  isPaintReorder: false,
  containerSizeHref: null
};

const Inventory = shapeType({
  enableItem: boolType(),
  totalQuantity: numberType()
});
if (Inventory.skip) {
  Inventory.skip('excludeInventory', false);
}
const paintDetails = params({ storeId: stringType(), configId: stringType() }).shape({
  rgb: shapeType({
    red: numberType(),
    green: numberType(),
    blue: numberType()
  })
});
if (paintDetails.skip) {
  paintDetails.skip('skipPaintDetails', true);
}
SuperSku.dataModel = extend({
  product: params({
    itemId: stringType().isRequired()
  }).shape({
    itemId: stringType(),
    availabilityType: shapeType({
      discontinued: boolType(),
      type: stringType()
    }),
    dataSources: stringType(), // do we need to ask for this?
    details: shapeType({
      collection: shapeType({
        collectionId: stringType()
      })
    }),
    paintDetails,
    fulfillment: client(params({ storeId: stringType() }).shape({
      backordered: boolType()
    })),
    identifiers: shapeType({
      brandName: stringType(),
      parentId: stringType(),
      isSuperSku: boolType()
    }),
    info: shapeType({
      paintBrand: stringType(),
      dotComColorEligible: boolType(),
      paintFamily: stringType()
    }),
    sizeAndFitDetail: client(shapeType({
      attributeGroups: arrayOfType({
        attributes: arrayOfType({
          attributeName: stringType(),
          dimensions: stringType()
        }),
        dimensionLabel: stringType(),
        productType: stringType()
      }),
    })),
    specificationGroup: client(arrayOfType({
      specTitle: stringType(),
      specifications: arrayOfType({
        specName: stringType(),
        specValue: stringType(),
      })
    }))
  }),
  metadata: params({
    parentId: stringType().isRequired()
  }).shape({
    attributes: arrayOfType({
      attributeName: stringType(),
      attributeValues: arrayOfType({
        swatchGuid: stringType(),
        value: stringType()
      }),
      isSwatch: boolType()
    }),
    childItemsLookup: arrayOfType({
      attributeCombination: stringType(),
      canonicalUrl: stringType(),
      isItemBackOrdered: boolType(),
      itemId: stringType()
    }),
    sizeAndFitDetail: shapeType({
      attributeGroups: arrayOfType({
        attributes: arrayOfType({
          attributeName: stringType(),
          dimensions: stringType()
        }),
        dimensionLabel: stringType(),
        productType: stringType()
      }),
    }),
    superDuperSku: shapeType({
      attributes: arrayOfType({
        attributeName: stringType(),
        attributeValues: arrayOfType({
          selected: boolType(),
          superSkuUrl: stringType(),
          value: stringType()
        })
      })
    })
  }),
  metadataPaint: params({
    configId: stringType().isRequired(),
    storeId: stringType().isRequired(),
    paintFamily: stringType().isRequired()
  }).shape({
    attributes: arrayOfType({
      attributeName: stringType(),
      attributeValues: arrayOfType({
        swatchGuid: stringType(),
        value: stringType()
      }),
      isSwatch: boolType()
    }),
    childItemsLookup: arrayOfType({
      attributeCombination: stringType(),
      canonicalUrl: stringType(),
      isItemBackOrdered: boolType(),
      itemId: stringType()
    }),
    sizeAndFitDetail: shapeType({
      attributeGroups: arrayOfType({
        attributes: arrayOfType({
          attributeName: stringType(),
          dimensions: stringType()
        }),
        dimensionLabel: stringType(),
        productType: stringType()
      }),
    }),
    superDuperSku: shapeType({
      attributes: arrayOfType({
        attributeName: stringType(),
        attributeValues: arrayOfType({
          selected: boolType(),
          superSkuUrl: stringType(),
          value: stringType()
        })
      })
    })
  }),
  mediaPriceInventory: params({
    itemIds: arrayOfType(stringType().isRequired()).isRequired(),
    storeId: stringType().isRequired()
  }).shape({
    productDetailsList: arrayOfType({
      itemId: stringType(),
      imageLocation: stringType(),
      onlineInventory: Inventory,
      pricing: shapeType({
        value: numberType(),
        original: numberType(),
        message: stringType(), // maybe
        mapAboveOriginalPrice: boolType() // maybe
      }),
      storeInventory: Inventory
    })
  })
}, SizeGuide);
