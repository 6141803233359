import React from 'react';
import PropTypes from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { Typography } from '@one-thd/sui-atomic-components';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { dataModel } from '../dataModel';
import { GROUP_TYPES } from '../helpers';
import { SpecificationsAccordionTitlePlaceholder } from './placeholders/SpecificationsAccordionTitlePlaceholder';

export const SpecificationsAccordionTitleComponent = (props) => {
  const {
    itemId,
  } = props;

  const { data, loading } = useDataModel('product', { variables: { itemId } });

  if (loading) return <SpecificationsAccordionTitlePlaceholder />;

  const subTitle = () => {
    const specificationGroup = data?.product?.specificationGroup;
    const dimensions = specificationGroup?.find((group) => group.specTitle === GROUP_TYPES.DIMENSIONS);
    const specifications = dimensions?.specifications;

    if (specifications?.length === 1) {
      return (
        <>
          {specifications[0].specName}: {specifications[0].specValue}
        </>
      );
    }

    /* eslint-disable */
    const height = specifications?.find((i) => i.specName === 'Assembled Height (in.)' || i.specName === 'Product Height (in.)')?.specValue;
    const width = specifications?.find((i) => i.specName === 'Assembled Width (in.)' || i.specName === 'Product Width (in.)')?.specValue;
    const depth = specifications?.find((i) => i.specName === 'Assembled Depth (in.)' || i.specName === 'Product Depth (in.)')?.specValue;
    const length = specifications?.find((i) => i.specName === 'Assembled Length (in.)' || i.specName === 'Product Length (in.)')?.specValue;
    /* eslint-enable */

    if ((!length && !depth) || !width || !height) {
      return null;
    }

    return (
      <>
        Dimensions: H {height}, W {width}, {depth ? `D ${depth}` : `L ${length}`}
      </>
    );
  };

  return (
    <div data-component="SpecificationsAccordionTitle">
      <Typography variant="h5" component="h3">Specifications</Typography>
      <div className="sui-mt-1">
        <Typography color="subtle">{subTitle()}</Typography>
      </div>
    </div>
  );
};

SpecificationsAccordionTitleComponent.displayName = 'SpecificationsAccordionTitleComponent';
SpecificationsAccordionTitleComponent.dataModel = dataModel;

const propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
};

SpecificationsAccordionTitleComponent.propTypes = propTypes;

const HydratedComponent = withHydrator({
  id: 'specifications-mobile',
  scrollBuffer: 750,
  delay: 1500,
  placeholder: <SpecificationsAccordionTitlePlaceholder />
}, SpecificationsAccordionTitleComponent);
const DynamicComponent = withDynamicComponent(HydratedComponent);

export const SpecificationsAccordionTitle = withErrorBoundary(DynamicComponent);

SpecificationsAccordionTitle.dataModel = dataModel;
SpecificationsAccordionTitle.propTypes = propTypes;
