import React from 'react';
import { string as stringType, bool as boolType } from 'prop-types';
import { Button } from '@one-thd/sui-atomic-components';
import {
  alias,
  arrayOf,
  shape,
  string,
  params,
  useDataModel,
} from '@thd-nucleus/data-sources';

const getSampleAnchorSku = (data = {}) => {
  const specDetails = (data?.product?.specificationGroup || []).find(
    (option) => option?.specTitle === 'Details'
  );
  const specAnchorSku = (specDetails?.specifications || []).find(
    (option) => option?.specName === 'Anchor Product OMSID'
  );
  return specAnchorSku?.specValue;
};

const PIPSampleAnchorSku = ({ itemId, showSampleAnchorSku }) => {

  const {
    data: productData,
    loading,
    error,
  } = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
    },
    skip: !itemId || !showSampleAnchorSku,
    ssr: false,
  });
  const sampleAnchorSku = getSampleAnchorSku(productData);

  if (!productData || loading || error) return null;

  return (
    <>
      {sampleAnchorSku && (
        <div className="sui-m-2">
          <Button variant="secondary" href={sampleAnchorSku}>
            Go to Main Product Page
          </Button>
        </div>
      )}
    </>
  );
};

PIPSampleAnchorSku.displayName = 'PIPSampleAnchorSku';
PIPSampleAnchorSku.propTypes = {
  itemId: stringType.isRequired,
  showSampleAnchorSku: boolType,
};
PIPSampleAnchorSku.defaultProps = {
  showSampleAnchorSku: false,
};
PIPSampleAnchorSku.dataModel = {
  clientOnlyProduct: alias('product')
    .params({
      itemId: string().isRequired(),
    })
    .shape({
      itemId: string(),
      dataSource: string(),
      specificationGroup: arrayOf(
        shape({
          specTitle: string(),
          specifications: arrayOf(
            shape({
              specName: string(),
              specValue: string(),
            })
          ),
        })
      ),
    }),
};

export { PIPSampleAnchorSku };
