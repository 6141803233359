import React, {
  createContext, useContext, useRef, useEffect, useState, useCallback
} from 'react';
import { element, func, string } from 'prop-types';

import { useDataModel } from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { fbtRecsDataModel } from '../dataModel';

const isFulfillable = (product) => {
  return !!(product?.fulfillment?.fulfillmentOptions || []).find((option) => option.fulfillable);
};

const isDiscontinued = (product) => {
  return !!(product?.availabilityType?.discontinued);
};

const getNearByStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  if (locations.length > 1) {
    const nearbyStore = locations.find((location) => !location.isAnchor);
    return nearbyStore;
  }
  return null;
};

const getLocalStore = (fulfillment) => {
  const locations = fulfillment?.locations || [];
  const localStore = locations.find((location) => location.isAnchor);
  return localStore;
};

const isBOPISHaveQuantity = (product) => {
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const bopisFulfillment = (pickupService?.services || []).find((service) => service.type === 'bopis');
  if (!bopisFulfillment) {
    return false;
  }
  const nearByStoreLocation = getNearByStore(bopisFulfillment);
  const localStoreLocation = getLocalStore(bopisFulfillment);
  return pickupService?.fulfillable
        && (localStoreLocation?.inventory?.quantity > 0 || nearByStoreLocation?.inventory?.quantity > 0);
};

const isDisplayableBasedOnFulfillment = (product) => {
  const deliveryService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'delivery');
  const pickupService = (product?.fulfillment?.fulfillmentOptions || []).find((option) => option.type === 'pickup');
  const shippingFulfillment = (deliveryService?.services || []).find((service) => service.type === 'sth');
  const bossFulfillment = (pickupService?.services || []).find((service) => service.type === 'boss');
  return !!(!isDiscontinued(product)
        && (deliveryService
        || shippingFulfillment
        || bossFulfillment
        || isBOPISHaveQuantity(product?.fulfillment)));
};
export const FBTContext = createContext({});
export const useFBTContext = () => useContext(FBTContext);

export const FBTProvider = ({
  itemId,
  originalConfigId,
  onChange,
  apiName,
  onLoadDynamicRecs,
  children
}) => {
  const { storeId, storeZip: zipCode, membershipInformation } = useStore();
  const loadedStoreId = useRef(storeId);
  const isFulfillableRef = useRef(true);
  const isDisplayableRef = useRef(true);

  const clientProductResponse = useDataModel('clientOnlyProduct', {
    variables: {
      itemId,
      storeId,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    },
    ssr: false
  });

  const opts = {
    variables: {
      anchorId: itemId,
      storeId,
      zipCode: zipCode || null,
      apiName,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
      skipInstallServices: true,
      dataSource: apiName
    },
    ssr: false
  };

  const RecsFbtResponse = useDataModel('recs', opts);

  const productResponse = useDataModel('product', {
    variables: {
      itemId,
      storeId,
      loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    },
    skip: (storeId !== '8119')
  });

  const loadedProduct = useRef(productResponse);

  const [checkedItems, setCheckedItems] = useState([itemId] || []);

  const handleDynamicRecsLoad = useCallback((resp) => {
    const products = resp?.data?.recs?.products;
    if (products?.length) {
      const additionalIds = (products.map(({ product }) => product?.identifiers?.itemId).filter((val) => val));
      setCheckedItems([itemId, ...additionalIds]);
    }
    if (onLoadDynamicRecs) {
      onLoadDynamicRecs(resp);
    }
  }, [itemId, onLoadDynamicRecs]);

  useEffect(() => {
    if (onChange && !RecsFbtResponse.loading) {
      handleDynamicRecsLoad(RecsFbtResponse);
    }
  }, [itemId, RecsFbtResponse, onChange]);

  const RecsFbtResponseComplete = RecsFbtResponse.data && !RecsFbtResponse.loading && RecsFbtResponse.called;
  const clientProductComplete = clientProductResponse.data
        && !clientProductResponse.loading
        && clientProductResponse.called;

  if (RecsFbtResponseComplete && clientProductComplete) {
    loadedStoreId.current = RecsFbtResponse.variables.storeId;
  }

  if (productResponse.data && !productResponse.loading && productResponse.called) {
    loadedProduct.current = productResponse;
  }

  if (clientProductComplete) {
    loadedProduct.current = clientProductResponse;
  }

  if (clientProductResponse.data?.product?.fulfillment) {
    const isProductFulfillable = isFulfillable(clientProductResponse.data?.product);
    isFulfillableRef.current = isProductFulfillable;
  } else if (clientProductResponse.data?.product) {
    isFulfillableRef.current = false;
  }

  if (clientProductResponse.data?.product?.availabilityType && clientProductResponse.data?.product?.fulfillment) {
    const isFbtDisplayable = isDisplayableBasedOnFulfillment(clientProductResponse.data?.product);
    isDisplayableRef.current = isFbtDisplayable;
  }

  const bundleType = RecsFbtResponse.data?.recs?.metadata?.apiName;

  const contextValue = {
    RecsFbtResponseData: RecsFbtResponse.data,
    RecsFbtResponseDataLoading: RecsFbtResponse.loading,
    storeId: loadedStoreId.current,
    isFulfillable: isFulfillableRef.current,
    isDisplayableBasedOnFulfillment: isDisplayableRef.current,
    productResponseData: loadedProduct.current.data,
    originalConfigId,
    productResponseLoading: loadedProduct.current.loading,
    bundleType,
    checkedItems,
    setCheckedItems,
    apiName
  };

  return (
    <FBTContext.Provider value={contextValue}>
      {children}
    </FBTContext.Provider>
  );
};

FBTProvider.propTypes = {
  itemId: string.isRequired,
  originalConfigId: string,
  apiName: string.isRequired,
  onChange: func,
  onLoadDynamicRecs: func,
  children: element.isRequired
};

FBTProvider.dataModel = fbtRecsDataModel;
FBTProvider.defaultProps = {
  originalConfigId: null,
  onChange: () => {},
  onLoadDynamicRecs: () => {}
};
