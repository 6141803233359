import React, { useEffect, useContext, useState, Suspense } from 'react';
import { bool, node, string } from 'prop-types';
import {
  Skeleton,
  SkeletonBlock,
  SkeletonContent,
  SkeletonLine
} from '@one-thd/sui-atomic-components';
import { QueryContext } from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import { dataModel } from '../dataModel';
import { OrderSampleContent } from './OrderSampleContent';
import { useOrderSample } from '../hooks/useOrderSample';
import { publish } from '../analytics';

const SampleDrawer = React.lazy(() => import(
  /* webpackChunkName: "order-sample-drawer" */
  './SampleDrawer/SampleDrawer')
);

const defaultState = {
  sampleDrawerRequested: false,
  gettingSampleData: false,
  sampleAvailability: 'Available'
};

export const OrderSample = ({
  children,
  disableDrawerCTA,
  disableSampleCall,
  itemId,
  link,
  showTitle
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('order-sample.ready'); }, []);

  const { dataSource } = useContext(QueryContext);
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';
  const isPLP = dataSource === 'searchNav';
  const [orderSampleState, setOrderSampleState] = useState(defaultState);
  const {
    sampleDrawerRequested,
    gettingSampleData,
    sampleAvailability
  } = orderSampleState;

  const { storeZip: zipCode } = useStore();
  const { data, loading: dataLoading } = useOrderSample({ disableSampleCall, itemId });
  
  const {
    discontinued,
    isSuperSku,
    parentId,
    price,
    sampleId,
    sampleProduct
  } = data;

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;
  
  if (dataLoading) {
    let heightUnit = '0';
    if (link) { // specific to PLP and PEP
      if (isPLP) {
        heightUnit = isMobile ? '7' : '9';
      } else { // PEP
        heightUnit = '12';
      }
    } else { // specific to PIP
      heightUnit = isMobile ? '24' : '12';
    }
    return (
      <div className="sui-grid sui-grid-cols-1 sui-w-full">
        <Skeleton disablePadding orientation="horizontal" grow>
          {!isMobile && (
            <SkeletonContent disablePadding density="loose" initialSize="1/4">
              <SkeletonBlock aspect="wide" height={heightUnit} />
            </SkeletonContent>
          )}
          <SkeletonContent disablePadding density="loose" initialSize="3/4" grow>
            <SkeletonLine variant="single" />
          </SkeletonContent>
        </Skeleton>
      </div>
    );
  }

  if (!sampleProduct || dataLoading || !sampleId || (!price && !discontinued)) {
    return null;
  }

  const shouldUseDrawer = !disableDrawerCTA && isSuperSku;

  if (!shouldUseDrawer) {
    let tempSampleAvailability = 'Available';
    if (!(sampleProduct.fulfillment?.fulfillmentOptions?.find((option) => option.fulfillable))) {
      tempSampleAvailability = 'Out of Stock';
    } else if (discontinued) {
      tempSampleAvailability = 'Discontinued';
    }
    if (sampleAvailability !== tempSampleAvailability) {
      setOrderSampleState((prevState) => {
        return {
          ...prevState,
          sampleAvailability: tempSampleAvailability
        };
      });
    }
  }

  const checkSamplesAvailable = ({ loading, orderSamples }) => {
    let sampleStatus = 'Available';
    if (!loading) {
      const samplesAvailable = (orderSamples || []).length;
      const inStock = !!orderSamples?.find((sample) => sample.inStockStatus);
      const notDiscontinued = !!orderSamples?.find((sample) => !sample.discontinued);

      const allSamplesOutOfStock = !!(samplesAvailable && !inStock);
      const allSamplesDiscontinued = !!(samplesAvailable && !notDiscontinued);
      if (allSamplesDiscontinued) {
        sampleStatus = 'Discontinued';
      } else if (allSamplesOutOfStock) {
        sampleStatus = 'Out of Stock';
      } else if (samplesAvailable === 0) {
        sampleStatus = 'No samples available';
      }
    }
    setOrderSampleState((prevState) => {
      return {
        ...prevState,
        gettingSampleData: loading,
        sampleAvailability: sampleStatus
      };
    });
  };

  const handleClick = async () => {
    if (shouldUseDrawer) {
      setOrderSampleState((prevState) => {
        return {
          ...prevState,
          gettingSampleData: true,
          sampleDrawerRequested: true,
          loadingSample: true,
        };
      });
    } else {
      const cartReqParams = {
        itemId: sampleId,
        quantity: 1,
        fulfillmentMethod: 'ShipToHome',
        fulfillmentLocation: zipCode
      };

      const cartOptions = {
        channel,
        host: window.location.origin.includes('localhost') ? 'https://www.homedepot.com' : window.location.origin,
        misship: false,
        paypal: false
      };

      window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
        cartReqParams,
        cartOptions,
      });
    }
    publish('order-sample.click');
  };

  const sampleDrawerClose = () => {
    setOrderSampleState((prevState) => {
      return {
        ...prevState,
        sampleDrawerRequested: false,
      };
    });
  };
  const shouldDrawerOpen = (sampleDrawerRequested && !gettingSampleData && sampleAvailability === 'Available');

  return (
    <div data-component="OrderSample">
      <OrderSampleContent
        handleClick={handleClick}
        link={link}
        loading={gettingSampleData}
        price={price}
        sampleAvailability={sampleAvailability}
        showTitle={showTitle}
      >
        {children}
      </OrderSampleContent>
      {sampleDrawerRequested
        && (
          <Suspense fallback={<div />}>
            <SampleDrawer
              anchorSampleId={sampleId}
              onClose={sampleDrawerClose}
              onSampleData={checkSamplesAvailable}
              open={shouldDrawerOpen}
              parentId={parentId}
            />
          </Suspense>
        )}
    </div>
  );
};

OrderSample.displayName = 'OrderSample';

OrderSample.dataModel = dataModel;

OrderSample.propTypes = {
  children: node,
  disableDrawerCTA: bool,
  // This is used to prevent the sample call until the consumer is ready
  // Ex: On PLP, we don't want to make a sample call when the user hovers over a super sku, only when they click
  disableSampleCall: bool,
  itemId: string.isRequired,
  link: bool,
  showTitle: bool
};

OrderSample.defaultProps = {
  children: 'Need a closer look?',
  disableDrawerCTA: false,
  disableSampleCall: false,
  link: false,
  showTitle: true
};