/* eslint-disable react/destructuring-assignment, react/jsx-props-no-spreading */
/**
 *  Do not edit this file! It is autogenerated from the harmony composer
 */
import React from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { useHydratedProps, showIf } from '@thd-olt-component-react/fusion-utils';
import { DynamicImporter } from './DynamicImporter';
import * as slots from './SyncImports';
import { SDUIImporter } from './SDUIImporter';
import { AccordionWrapper } from './util/AccordionWrapper';

export const Slot = (props) => {
  const {
    name,
  } = props;
  if (!props.config.slots[name]) {
    return (
      <div>slot {name} is not found in config</div>
    );
  }

  if (props.config.slots[name].isSDUI) {
    return <SDUIImporter {...props} slot={name} fusionSlotName={name} />;
  }

  if (props.config.slots[name].isDynamic) {
    return <DynamicImporter {...props} slot={name} fusionSlotName={name} />;
  }

  const slotName = name.charAt(0).toUpperCase() + name.substring(1, name.length);
  const Component = slots[slotName];
  if (!Component) {
    // eslint-disable-next-line no-console
    console.warn(`Slot Error, component ${name} not found in slots`, slots);
    return null;
  }
  const slot = props.config.slots[name];

  if (slot.isAccordion) {
    // eslint-disable-next-line no-console
    console.warn(`Warning: slot ${name} is an accordion but is not dynamic. This is not ideal for performance.`);
    return (
      <AccordionWrapper config={props.config} name={name}>
        <Component {...props} config={props.config} fusionSlotName={name} slot={name} fusionComponentName="accordion" />
      </AccordionWrapper>
    );
  }

  // @todo Component.name will not work when minified so they must define a display name
  const component = slot.components.find((comp) => (comp.displayName
    ? comp.displayName === (Component.displayName || Component.name)
    : comp.export === (Component.displayName || Component.name))
  );

  if (!component) {
    // eslint-disable-next-line no-console
    console.warn(`Component ${Component.displayName || Component.name} not found in config for slot ${slotName}`);
    return null;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hydratedProps = useHydratedProps(component || slot.components, { mergeArray: true });
  return <Component {...props} {...hydratedProps} fusionSlotName={name} fusionComponentName={component.name} />;
};

const dataModels = Object.values(slots).filter((slot) => slot.dataModel);
Slot.dataModel = extend({}, ...dataModels);

Slot.propTypes = {
  name: PropTypes.string.isRequired,
  config: PropTypes.shape({
    slots: PropTypes.shape({})
  }).isRequired,
};