import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sharebelt, ShareFavorite } from '@thd-olt-component-react/thd-sharebelt';
import { extend } from '@thd-nucleus/data-sources';
import { ProductDetails as ProductDetailsOG } from '../ProductDetails.component';
import * as styles from './product-details.module.scss';

export const PIPProductDetails = ({
  itemId, hideRatingsIfHDPPSku, qaIndex, ratingIndex, ...props
}) => {
  const qaSelector = '#product-section-qa';
  const reviewTarget = '#product-section-rr';
  const setExpandedRef = useRef();
  const ratingsRef = useRef();
  const qaRef = useRef();
  const { configId } = props;

  const handleScrolling = (element) => {
    const STICKY_HEADER_HEIGHT = 125;
    const top = element.getBoundingClientRect().top + window.pageYOffset - STICKY_HEADER_HEIGHT;
    window.scroll({
      top,
      behavior: 'smooth'
    });
  };

  const onClick = useCallback((evt, target, index) => {
    window.LIFE_CYCLE_EVENT_BUS.trigger('product-details.ratings-click');
    let element = ratingsRef.current;
    let targetIndex = ratingIndex || index;
    if (target === qaSelector) {
      element = qaRef.current;
      targetIndex = qaIndex;
    }
    if (setExpandedRef.current) {
      setExpandedRef.current(targetIndex)(evt, true);
    }
    handleScrolling(element);
  }, []);

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.on('accordion.mount', ({ output }) => {
      const { wrapperIds, setExpanded } = output;
      setExpandedRef.current = setExpanded;
      ratingsRef.current = document.querySelector('#' + wrapperIds[ratingIndex]);
      qaRef.current = document.querySelector('#' + wrapperIds[qaIndex]);
    });

  }, []);

  return (

    <ProductDetailsOG itemId={itemId} configId={configId}>
      <ProductDetailsOG.Badge itemId={itemId} />
      <ProductDetailsOG.BrandCollection
        showBrandHyperLink
        hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
      />
      <div className={styles['product-details__badge-title--wrapper']}>
        <ProductDetailsOG.Title />
      </div>
      <div className={styles['product-details__favorites']}>
        <Sharebelt itemId={itemId}>
          <ShareFavorite showCircle showFavoritesCount />
        </Sharebelt>
      </div>
      <ProductDetailsOG.Ratings
        showBadge
        target={reviewTarget}
        offsetselector="#sticky-nav"
        qaselector={qaSelector}
        onClick={onClick}
        useHeaderIndex={!!(ratingIndex || qaIndex)}
        hideRatingsIfHDPPSku={hideRatingsIfHDPPSku}
      />
    </ProductDetailsOG>
  );
};

PIPProductDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
  hideRatingsIfHDPPSku: PropTypes.bool,
  configId: PropTypes.string,
  ratingIndex: PropTypes.number,
  qaIndex: PropTypes.number,
};
PIPProductDetails.defaultProps = {
  hideRatingsIfHDPPSku: false,
  configId: undefined,
  ratingIndex: undefined,
  qaIndex: undefined
};

PIPProductDetails.displayName = 'PIPProductDetails';

PIPProductDetails.dataModel = extend({}, ProductDetailsOG, Sharebelt, ShareFavorite);
