import React, { ReactElement } from 'react';

type ComponentProps = {
  children: (props: any) => ReactElement<any, any>;
}

const ChildrenComponent: React.FC<ComponentProps>  = ({ children }) => {
  // match business rules
  return children({ props: {} });
};

export function withBypassComponent<T>(Component: JSX.Element| React.FC, enabled = true) {
  if (enabled) {
    return Component;
  }
  return ChildrenComponent as T;
}
