import { useHydratedProps } from '@thd-olt-component-react/fusion-utils';

export const AccordionWrapper = (props) => {
  const { name, children } = props;

  const slot = props.config.slots[name];
  const components = slot.components
    .flat()
    .map((sc) => sc.components)
    .flat();

  const hydratedProps = useHydratedProps(components);
  components.forEach((com, index) => {
    // eslint-disable-next-line no-param-reassign
    com.props = hydratedProps[index];
  });

  return children;
};