/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentType, ReactNode } from 'react';
import { extend } from '@thd-nucleus/data-sources';

type ContainerType<P> = ComponentType<P>;

// Define the HOC function
export const withContainer = <P extends {}>(
  WrappedComponent: ComponentType<P>,
  Container: ContainerType<{ children: ReactNode }>
) => {

  const Wrapped = (props: P) => (
    <div>
      <Container>
        <WrappedComponent {...props} />
      </Container>
    </div>
  );

  Wrapped.dataModel = extend({}, WrappedComponent);

  return Wrapped;
};
