import { useEffect } from 'react';
import { useNavigate } from '@thd-olt-component-react/router';

export const useEventListener = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // supersku
    window.LIFE_CYCLE_EVENT_BUS.on('super-sku.change', ({ output }) => {
      const { item, slug, canonicalUrl } = output;
      if (canonicalUrl) {
        navigate(canonicalUrl, { state: { disableScroll: true } });
      }
    });

    // @todo sticky nav

  }, []);
};
